/** @jsx jsx */
import { jsx, Box, Grid, Card } from 'theme-ui'
import Layout from '../../components/layout'
import Hero from '../../components/hero'
import Wrapper from '../../components/layout/wrapper'
import Inner from '../../components/layout/inner'
import Twitter from '../../components/twitter'
import { graphql } from 'gatsby'
import { responsive } from '../../utils/helpers'
import LeagueTable from '../../components/leagueTable'
import { GetComponent } from '../../richtext'
import Accordian from '../../components/accordion'
import NextMatch from '../../components/nextMatch'
import { Link } from 'gatsby'
import MetaData from '../../components/metadata'
import Feed from '../../components/feed'
import SectionHeader from '../../components/sectionHeader'

const Home = props => {
  const cardSX = {
    position: 'relative',
    pb: 16,
  }
  const page = props.data?.contentfulHome

  const cardFooterSX = {
    textAlign: 'center',
    color: 'black',
    display: 'block',
    fontWeight: 700,
    position: 'absolute',
    bottom: 7,
    left: '0px',
    right: '0px',
  }

  return (
    <Layout {...props}>
      <MetaData />

      <Hero data={page.hero} sx={{ marginBottom: 8 }} />

      <Wrapper>
        <Inner>
          <Grid columns={responsive(1, 2)} mb={8}>
            <Card variant="slim" sx={cardSX}>
              <Accordian heading="Next Match" open noBorder>
                <Box p={2}>
                  <NextMatch
                    tickets
                    // travel
                    live
                    location
                    variant="small"
                    color="black"
                  />
                </Box>
                <Link to="/match-centre/fixtures" sx={cardFooterSX}>
                  See all fixtures
                </Link>
              </Accordian>
            </Card>
            <Card variant="slim" sx={cardSX}>
              <Accordian heading="League table" open noBorder>
                <LeagueTable variant="small" />
                <Link to="/match-centre/league" sx={cardFooterSX}>
                  See the standings
                </Link>
              </Accordian>
            </Card>
          </Grid>

          <SectionHeader
            title="Latest News"
            button={{ label: 'See more', url: '/news/' }}
          />
          <Feed
            location={props.location}
            featured
            pagination
            itemsToShow={11}
            data={props.data}
          />
        </Inner>
      </Wrapper>

      <GetComponent selector="ContentfulPromo" data={page.promoOne} />
      <GetComponent selector="ContentfulGallery" data={page.gallery} />
      <GetComponent selector="ContentfulPromo" data={page.promoTwo} />

      <Twitter />
    </Layout>
  )
}

export default Home

export const pageQuery = graphql`
  query HomePage {
    contentfulHome {
      title
      hero {
        ...HeroFragment
      }
      gallery {
        ...GalleryFragment
      }
      promoOne {
        ...PromoFragment
      }
      promoTwo {
        ...PromoFragment
      }
    }
    allContentfulNews(sort: { fields: date, order: DESC }, limit: 11) {
      edges {
        node {
          hero {
            ...HeroFragment
          }
          date
          title
          slug
          video
          videoEmbed
          excerpt
          thumbnail {
            file {
              url
            }
            gatsbyImageData(layout: FIXED, width: 550, height: 550)
          }
          article {
            ... on ContentfulArticle {
              title
              main {
                raw
                references {
                  ...ArticleFragment
                }
              }
            }
          }
          category {
            name
            id
            slug
          }
        }
      }
    }
  }
`
